<template>
  <b-form-group
    class="mt-1"
    label-for="boolInput"
  >
    <label
      v-if="type != 'statement'"
      v-html="name"
    />

    <!-- Boolean -->
    <b-form-radio-group
      v-if="type === 'bool'"
      v-model="answer"
      :options="options"
      :disabled="true"
      @change="answerChanged"
    />

    <!-- Rating -->
    <b-form-rating
      v-if="type === 'rating'"
      v-model="answer"
      size="lg"
      inline="inline"
      no-border="no-border"
      variant="warning"
      :disabled="true"
      class="ml-1 mr-1"
      @change="answerChanged"
    />
    <strong v-if="type === 'rating'" :style="{ fontSize: '1.15rem' }">
      {{ answer ? answer : 'N/A' }}
    </strong>

    <!-- Text -->
    <b-form-textarea
      v-if="type === 'text'"
      v-model="answer"
      rows="3"
      :disabled="true"
      @change="answerChanged"
    />

    <!-- Statement -->
    <div v-if="type === 'statement'">
      <b-card
        v-html="showSeeMoreButton && showShortStatement ? shortStatement : name"
        class="mb-0"
      />
      <b-button
        v-if="showSeeMoreButton"
        size="sm"
        :variant="showShortStatement ? 'info' : 'warning'"
        @click="showShortStatement = !showShortStatement"
      >
        {{ showShortStatement ? $t('message.see_more') : $t('message.see_less') }}
      </b-button>
    </div>

  </b-form-group>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  BFormGroup,
  BFormRadioGroup,
  BFormRating,
  BFormTextarea,
} from 'bootstrap-vue'
import i18n from '@/libs/i18n'

export default {
  components: {
    BFormGroup,
    BFormRadioGroup,
    BFormRating,
    BFormTextarea,
  },

  props: {
    behaviour: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      name: this.behaviour.name,
      type: this.behaviour.type,
      answer: this.behaviour.answer,
    }
  },

  computed: {
    shortStatement() {
      if (this.type === 'statement') {
        return this.name.slice(0, 100) + "..."
      }
    },
    showSeeMoreButton() {
      if (this.type === 'statement' && this.name?.length > 100) return true
      return false
    }
  },

  methods: {
    answerChanged(answer) {
      this.$emit('answered', answer)
    },
  },

  setup() {
    const showShortStatement = ref(true)

    const options = [
      { text: i18n.t('message.yes'), value: 'true' },
      { text: 'No', value: 'false' },
      { text: 'N/A', value: 'unknown' },
    ]

    return {
      options,
      showShortStatement,
    }
  },
}
</script>
