import { ref } from '@vue/composition-api'
import useNotifications from '@/composables/useNotifications'
import { useRouter } from '@core/utils/utils'
import i18n from '@/libs/i18n'
import realmConnection from '@/views/habit/realm'

export default function useCommitmentView() {
  const { showErrorMessage } = useNotifications()
  const { route } = useRouter()
  const { getItemsWithAggregate, ObjectId } = realmConnection()

  const commitment = ref({})
  const { commitmentId } = route.value.params
  const isLoading = ref(true)

  const show = async () => {
    try {
      const query = { _id: ObjectId(commitmentId) }

      const pipeline = [
        { $match: query },
        { $lookup: { from: 'worker', localField: 'assignee', foreignField: '_id', pipeline: [ { $project: { name: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ], as: 'assignee' } },
        { $lookup: { from: 'worker', localField: 'supervisor', foreignField: '_id', pipeline: [ { $project: { name: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ], as: 'supervisor' } },
        { $addFields: { _id: { $toString: "$_id" }, assignee: { $arrayElemAt: ["$assignee", 0] }, supervisor: { $arrayElemAt: ["$supervisor", 0] } } }
      ]
    
      const items = await getItemsWithAggregate({ collection: 'commitment', pipeline })
      if (!items?.[0]) throw new Error('Item not found')

      commitment.value = items[0]
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.commitment_fetch_error'))
    } finally {
      isLoading.value = false
    }
  }

  return {
    show,
    commitment,
    isLoading
  }
}
